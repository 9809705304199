 <template>
  <footer class="footer">
    <div class="footer__wrapper wrapper" ref="footer">
      <div class="footer__content">
        <div class="footer__box footer__box--logo">
          <BaseLogo class="footer__logo"/>
        </div>

        <div
          class="footer__box footer__box--links"
          v-if="shownCategories.length > 0"
        >
          <p class="footer__title">{{ $t('base-footer-shop-categories') }}</p>
          <nuxt-link
            class="footer__link"
            v-for="category in shownCategories"
            :to="{ name: 'catalog-slug', params: { slug: category.slug }}"
            :key="category.id"
          >
            {{ category.name }}
          </nuxt-link>
          <nuxt-link 
            class="footer__link"
            to="/manufacturer"
          >
            {{ $t('base-footer-manufacturers') }}
          </nuxt-link>
        </div>

        <div class="footer__box footer__box--links" v-if="companyPages.length > 0">
          <p class="footer__title">{{ PAGE_TYPE_NAMES[PAGE_TYPES.COMPANY] }}</p>
          <nuxt-link
            class="footer__link"
            v-for="companyPage in companyPages"
            :to="{ name: 'page-slug', params: { slug: companyPage.slug }}"
            :key="companyPage.id"
          >
            {{ companyPage.name }}
          </nuxt-link>
        </div>

        <div class="footer__box footer__box--links" v-if="legalInfoPages.length > 0">
          <p class="footer__title">{{ PAGE_TYPE_NAMES[PAGE_TYPES.LEGAL_INFO] }}</p>

          <nuxt-link
            class="footer__link"
            v-for="legalInfoPage in legalInfoPages"
            :to="{ name: 'page-slug', params: { slug: legalInfoPage.slug }}"
            :key="legalInfoPage.id"
          >
            {{ legalInfoPage.name }}
          </nuxt-link>
          <a href="https://www.iubenda.com/privacy-policy/57322054" class="footer__link" title="Privacy Policy" target="_blank">
            {{ $t('base-footer-privacy-policy') }}
          </a>
          <a href="https://www.iubenda.com/privacy-policy/57322054/cookie-policy" class="footer__link" title="Cookie Policy" target="_blank">
            {{ $t('base-footer-cookie-policy') }}
          </a>
        </div>

        <div class="footer__box footer__box--links">
          <p class="footer__title">{{ $t('base-footer-stay-in-touch') }}</p>
          <div class="footer__social">
            <a href="https://www.facebook.com/yachtneeds" target="_blank" class="footer__social-link" title="Facebook">
              <svg-icon name="facebook-logo" class="footer__ico"></svg-icon>
            </a>
            <a href="https://www.instagram.com/yachtneeds/" target="_blank" class="footer__social-link" title="Instagram">
              <svg-icon name="instagram-logo" class="footer__ico"></svg-icon>
            </a>
            <a href="https://twitter.com/YachtNeedsapp/" target="_blank" class="footer__social-link" title="Twitter">
              <svg-icon name="twitter-logo" class="footer__ico"></svg-icon>
            </a>
            <a href="https://www.youtube.com/channel/UCa0LoZBm1Tlx7_r-cH0X9vg" target="_blank" class="footer__social-link" title="Youtube">
              <svg-icon name="youtube-logo" class="footer__ico"></svg-icon>
            </a>
            <a href="https://www.linkedin.com/company/10269979" target="_blank" class="footer__social-link" title="LinkedIn">
              <svg-icon name="linkedin-logo" class="footer__ico"></svg-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="footer__content footer__content--bottom-block">
        <div class="footer__box footer__box--address">
          <p class="footer-address__text">{{ $t('base-footer-est-ouest') }}</p>
          <p class="footer-address__text">{{ $t('base-footer-98000-monaco') }}</p>
        </div>

        <div class="footer__box footer__box--payment">
          <div class="footer-payment__ico-wrap">
            <svg-icon class="checkout__payment-ico" name="payment-visa-white"></svg-icon>
          </div>
          <div class="footer-payment__ico-wrap">
            <svg-icon class="checkout__payment-ico" name="payment-mastercard-white"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { PAGE_TYPES, PAGE_TYPE_NAMES } from '@/utils/types/page'

  export default {
    components: {
      BaseLogo:() => import('@/components/base/BaseLogo')
    },
    data() {
      return {
        PAGE_TYPE_NAMES,
        PAGE_TYPES,
        once:true
      }
    },
    computed: {
      ...mapState({
        companyPages: state => state.footer.companyPages,
        legalInfoPages: state => state.footer.legalInfoPages
      }),
      ...mapGetters({
        shownCategories: 'shared/shownCategories'
      })
    },
    methods: {
      ...mapActions({
        fetchFooterData: 'footer/fetchFooterData'
      }),
      intersectionCallback(entries, observer) {
        entries.forEach((entry) => {
          if (entry.isIntersecting && this.once) {
            this.fetchFooterData();
            this.once = false;
          }
        });
      }
    },
    mounted() {
      const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.1,
      };
      const observer = new IntersectionObserver(this.intersectionCallback, options);
      let elementToObserve = this.$refs.footer;
      if(elementToObserve) observer.observe(elementToObserve);
    }
  };
</script>

<style scoped lang="scss">
  .footer {
    background-color: var(--secondary-color-medium);
    z-index: -1;
  position: relative;
  }

  .footer__wrapper {
    height: 100%;
    padding: 48px 25px 32px 25px;
    overflow: hidden;
    position: relative;

    @media (--viewport-tablet) {
      padding: 64px 16px 56px 16px;
    }
  }

  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    flex-wrap: wrap;

    @media (width >= 1100px) {
      flex-wrap: nowrap;
    }
  }

  .footer__box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 42px;

    @media (width >= 1100px) {
      width: auto;
      margin-bottom: 0;
    }
  }

  .footer__box--links {
    padding-right: 10px;
    width: 100%;

    @media (--viewport-mobile) {
      width: auto;
    }

    @media (--viewport-tablet) {
      padding-right: 30px;
    }
  }

  .footer__box--logo {
    width: 100%;

    @media (width >= 1100px) {
      padding-right: 70px;
      width: auto;
    }
  }

  .footer__logo {
    @media (--viewport-desktop) {
      margin: 0;
    }
  }

  .footer__title {
    margin-bottom: 24px;
    font-style: var(--secondary-font);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff;
    text-align: left;


    @media (--viewport-mobile) {
      font-size: 1.25rem;
    }

    @media (--viewport-tablet) {
      margin-bottom: 18px;
    }
  }

  .footer__link {
    padding: 6px 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;

    @media (--viewport-mobile) {
      font-size: 1rem;
    }

    &:hover {
      color: var(--brand-color);
    }

    @media (--viewport-tablet) {
      padding: 4px 0;
    }
  }

  .footer__social {
    text-align: left;
    max-width: 133px;
    margin: 0 -8px;

    @media (--viewport-tablet) {
      max-width: 180px;
      margin: 0 -12px;
    }
  }

  .footer__social-link {
    display: inline-block;
    padding: 0 8px;
    box-sizing: border-box;
    margin-bottom: 24px;

    @media (--viewport-tablet) {
      padding: 0 12px;
    }
  }

  .footer__ico {
    width: 40px;
    height: 40px;
    fill: #ffffff;

    &:hover {
      fill: var(--brand-color);
    }

    @media (--viewport-mobile) {
      width: 48px;
      height: 48px;
    }

    @media (--viewport-tablet) {
      width: 32px;
      height: 32px;
    }
  }

  .footer__content--bottom-block {
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    top: -20px;

    @media (--viewport-tablet) {
      top: 0;
    }
  }

  .footer__box--address {
    width: 100%;
    padding: 0;

    @media (--viewport-desktop) {
      max-width: 315px;
      display: block;
      margin-top: 24px;
    }
  }

  .footer-address__text {
    font-size: .875rem;
    line-height: 1.4;
    font-weight: 400;
    color: var(--color-grey-5);
  }

  .footer__box--payment {
    display: flex;
    flex-direction: row;
    margin: 0 -20px 0 -10px;

    @media (--viewport-desktop) {
      margin-top: 20px;
    }

  }

  .footer-payment__ico-wrap {
    margin: 0 10px;
    width: 33%;
  }

  .checkout__payment-ico {
    height: 40px;
    max-width: 64px;
  }
</style>
